<template>
    <div>
        <b-container>
            <b-row cols="3" align-v="center">
                <b-col>
                    Per Page: <b-select
                    style="width: 6rem"
                    :options="perPageOption"
                    v-model="perPage"
                    @change="perPageChange"
                ></b-select>
                </b-col>
                <b-col>
                    <b-button variant="primary" @click="refreshDeviceList">Refresh List</b-button>
                </b-col>
                <b-col>
                    <b-button size="sm" style="margin-right: 20px; margin-bottom: 10px; margin-top: 10px" @click="selectAllRows">Select all</b-button>
                    <b-button size="sm" style="margin-right: 20px; margin-bottom: 10px; margin-top: 10px" @click="clearSelected">Clear selected</b-button>
                    <b-badge size="sm" variant="info">Sel: {{selected.length}}</b-badge>
                </b-col>
            </b-row>
        </b-container>
        <p></p>
        <b-table
            striped
            bordered
            small
            hover
            head-variant="light"
            selectable
            @row-selected="onRowSelected"
            @row-contextmenu="rightClicked"
            ref="fwDevListTable"
            select-mode="multi"
            responsive="sm"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items="fwItems"
            :fields="fields"
            :busy="isBusy"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
        >
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </template>
            <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                    <span aria-hidden="true">&check;</span>
                    <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                </template>
            </template>
        </b-table>
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
        ></b-pagination>
    </div>
</template>

<script>
export default {
    name: "FwDevList",
    data() {
        return {
            fields: [
                {key: 'selected', label: 'Selected'},
                {key: 'deviceId', label: 'Device ID', sortable: true},
                {key: 'deviceType', label: 'Device Type', sortable: true},
                {key: 'user', label: 'Assigned User', sortable: true},
                {key: 'fwCurVerStr', label: 'Current Ver.', sortable: true},
                {key: 'fwTargetVerStr', label: 'Target Ver', sortable: true},
                {key: 'fwUpdatePriority', label: 'Update Priority', formatter: this.priFormat, sortable: true},
                {key: 'fwUpdate', label: 'Update', formatter: this.updateFormat, sortable: true},
                {key: 'fwPicCurVerStr', label: 'Cur WD Ver.', sortable: true},
                {key: 'fwPicTargetVerStr', label: 'Target WD Ver', sortable: true},
                {key: 'fwPicUpdatePriority', label: 'WD Priority', formatter: this.priFormat, sortable: true},
                {key: 'fwPicUpdate', label: 'WD Update', formatter: this.updateFormat, sortable: true},
            ],
            perPageOption: [5, 10, 25, 50, 100],
            priorityOptions: [
                {value: 0, text: 'During Update Hours'},
                {value: 1, text: 'When Clear Schedule for 1 hour'},
                {value: 2, text: 'Immediately'}
            ],
            updateOptions: [
                {value: 0, text: 'Do Nothing'},
                {value: 1, text: 'Recently Updated'},
                {value: 2, text: 'Update to Latest'},
                {value: 3, text: 'Update to Target'}
            ],
            sortBy: 'deviceId',
            isBusy: true,
            sortDesc: false,
            selected: [],
            selectedUser: null,
            perPage: 5,
            currentPage: 1,
            totalRows: 1,
            checkOpt: true,
            filter: null,
            filterOn: ['deviceId']
        }
    },
    created() {
        this.isBusy = true;
        this.perPage = this.$store.getters.getAdminFwDevPerPage;
        this.$store.dispatch('getDeviceFwList').then(() => {
            this.totalRows = this.$store.getters.deviceFwList.length;
            this.isBusy = false;
        }).catch(() => {

        });
    },
    mounted() {

    },
    methods: {
        selectAllRows() {
            this.$refs.fwDevListTable.selectAllRows()
        },
        clearSelected() {
            this.$refs.fwDevListTable.clearSelected()
        },
        perPageChange(value) {
            this.$store.dispatch('updateAdminFwDevPerPage', value);
        },
        refreshDeviceList() {
            this.isBusy = true;
            this.$store.dispatch('getDeviceFwList').then(() => {
                this.totalRows = this.$store.getters.deviceFwList.length;
                this.isBusy = false;
            });
        },
        onRowSelected(items) {
            this.selected = items;
            this.$store.commit('storeSelectedDevFw', this.selected);
        },
        // eslint-disable-next-line no-unused-vars
        rightClicked (item) {
            this.$store.commit('storeSelectedDevFw', this.selected);
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        priFormat(value) {
            return this.priorityOptions[value].text;
        },
        updateFormat(value) {
            return this.updateOptions[value].text;
        }
    },
    computed: {
        fwItems() {
            if (this.$store.getters.deviceFwList !== null) {
                let devList = this.$store.getters.deviceFwList;
                let insectList = [];
                for (let i = 0; i < devList.length; i++) {
                    if (devList[i].deviceType === "InsectControl")
                        insectList.push(devList[i]);
                }
                return insectList;
            }
            else
                return null;
        }
    }
}
</script>

<style scoped>

</style>
