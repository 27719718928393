<template>
<div>
    <h3>FW Manager</h3>
    <hr>
    <div class="text-center">
        <h3>FW List</h3>
        <hr>
    </div>
    <b-tabs content-class="mt-3">
        <b-tab title="Insect Control" active>
            <div @contextmenu.prevent.stop="handleFwClick($event)" class="item-wrapper">
                <fw-list-insect></fw-list-insect>
            </div>
        </b-tab>
        <b-tab title="Cool Mist">
            <div @contextmenu.prevent.stop="handleFwClick($event)" class="item-wrapper">
                <fw-list-cool-mist></fw-list-cool-mist>
            </div>
        </b-tab>
        <b-tab title="Pic WD">
            <div @contextmenu.prevent.stop="handleFwClick($event)" class="item-wrapper">
                <fw-list-pic-wd></fw-list-pic-wd>
            </div>
        </b-tab>
    </b-tabs>
    <hr>
    <div class="text-center">
        <h3>FW Dev List</h3>
        <hr>
    </div>
    <b-tabs content-class="mt-3">
        <b-tab title="Insect Control Devices" active>
            <div @contextmenu.prevent.stop="handleFwDevClick($event)" class="item-wrapper">
                <fw-dev-list></fw-dev-list>
            </div>
        </b-tab>
        <b-tab title="Cool Mist Devices">
            <div @contextmenu.prevent.stop="handleFwDevClick($event)" class="item-wrapper">
                <fw-cool-dev-list></fw-cool-dev-list>
            </div>
        </b-tab>
    </b-tabs>
    <hr>
    <div>
        <vue-simple-context-menu
            elementId="myUniqueId4"
            :options="fwOptions"
            ref="vueSimpleContextMenu4"
            @option-clicked="contextFwSelect"
        >
        </vue-simple-context-menu>
    </div>
    <div v-if="hasSelection">
        <vue-simple-context-menu
            elementId="myUniqueId3"
            :options="fwDevOptions"
            ref="vueSimpleContextMenu3"
            @option-clicked="contextFwDevSelect"
        >
        </vue-simple-context-menu>
    </div>
    <add-fw-dialog></add-fw-dialog>
    <fw-dev-pri-dialog></fw-dev-pri-dialog>
    <fw-dev-mode-dialog></fw-dev-mode-dialog>
    <fw-pic-pri-dialog></fw-pic-pri-dialog>
    <fw-pic-mode-dialog></fw-pic-mode-dialog>
    <add-dev-to-fw-list-dialog></add-dev-to-fw-list-dialog>
</div>
</template>

<script>
import FwDevList from "@/components/AdminModules/General/FwInsectDevList";
import FwCoolDevList from "@/components/AdminModules/General/FwCoolDevList";
import FwListInsect from "@/components/AdminModules/General/FwListInsect";
import FwListCoolMist from "@/components/AdminModules/General/FwListCoolMist";
import FwListPicWd from "@/components/AdminModules/General/FwListPicWd";
import AddFwDialog from "@/components/AdminModules/General/AddFwDialog";
import FwDevPriDialog from "@/components/AdminModules/General/FwDevPriDialog";
import FwDevModeDialog from "@/components/AdminModules/General/FwDevModeDialog";
import FwPicPriDialog from "@/components/AdminModules/General/FwPicPriDialog";
import FwPicModeDialog from "@/components/AdminModules/General/FwPicModeDialog";
import AddDevToFwListDialog from "@/components/AdminModules/General/AddDevToFwListDialog";
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css'
import VueSimpleContextMenu from 'vue-simple-context-menu'
import { EventBus } from '@/plugins/event-bus';

export default {
    name: "FwManager",
    data() {
        return {
            fwDevOptions: [
                {value: 0, name: 'Change FW Target'},
                {value: 1, name: 'Change Priority'},
                {value: 2, name: 'Change Update Mode'},
                {value: 'div1', type: 'divider'},
                {value: 4, name: 'Change WD Target'},
                {value: 5, name: 'Change WD Priority'},
                {value: 6, name: 'Change WD Update Mode'},
            ],
            fwOptions: [
                {value: 0, name: 'Add New FW'},
                {value: 1, type: 'divider'},
                {value: 2, name: 'Remove FW'},
            ]
        }
    },
    components: {
        FwDevList,
        FwListInsect,
        FwListCoolMist,
        FwListPicWd,
        AddFwDialog,
        FwDevPriDialog,
        FwDevModeDialog,
        FwPicPriDialog,
        FwPicModeDialog,
        AddDevToFwListDialog,
        VueSimpleContextMenu,
        FwCoolDevList
    },
    created() {

    },
    mounted() {

    },
    methods: {
        handleFwDevClick (event) {
            if (this.$store.getters.selectedDeviceFw !== null) {
                if (this.$store.getters.selectedDeviceFw.length > 0)
                    this.$refs.vueSimpleContextMenu3.showMenu(event);
            }
        },
        handleFwClick (event) {
            this.$refs.vueSimpleContextMenu4.showMenu(event);
        },
        contextFwDevSelect(item) {
            //console.log(item.option.name);
            if (this.fwDevOptions[0].name === item.option.name) {
                let selListFwDev = JSON.parse(JSON.stringify(this.$store.getters.selectedDeviceFw));
                if (selListFwDev.length > 0) {
                    const curFwSel = this.$store.getters.selectedFw;

                    for (let i = 0; i < selListFwDev.length; i++) {
                        let curFwDev = selListFwDev[i];
                        if (curFwDev.deviceType !== curFwSel.deviceType) {
                            this.$bvToast.toast('Device Type and FW Type Need To Match', {
                                title: 'FW Target',
                                variant: 'danger',
                                solid: true
                            });
                            continue;
                        }

                        curFwDev.fwTargetVerStr = curFwSel.verStr;
                        curFwDev.fwTargetVerNum = curFwSel.verNum;
                        this.$store.dispatch('setDeviceFwAction', curFwDev).then(() => {
                            this.$bvToast.toast('Device FW Target Changed', {
                                title: 'FW Target',
                                variant: 'success',
                                solid: true
                            });
                            this.$store.dispatch('getDeviceFwList');
                        }).catch(() => {
                            this.$bvToast.toast('Error Changing Device FW Target', {
                                title: 'FW Target',
                                variant: 'danger',
                                solid: true
                            });
                        });
                    }
                }
            }
            else if (this.fwDevOptions[1].name === item.option.name) {
                EventBus.$emit("ChangeFwPriority");
            }
            else if (this.fwDevOptions[2].name === item.option.name) {
                EventBus.$emit("ChangeFwMode");
            }
            else if (this.fwDevOptions[4].name === item.option.name) {
                let selListFwDev = JSON.parse(JSON.stringify(this.$store.getters.selectedDeviceFw));
                if (selListFwDev.length > 0) {
                    const curFwSel = this.$store.getters.selectedFw;

                    for (let i = 0; i < selListFwDev.length; i++) {
                        let curFwDev = selListFwDev[i];
                        if (curFwDev.fwCurVerNum < 400) {
                            this.$bvToast.toast('Device FW Needs to be Greater than 0.4.0', {
                                title: 'WD Target',
                                variant: 'danger',
                                solid: true
                            });
                            continue;
                        }
                        if (curFwSel.deviceType !== "PicWd") {
                            this.$bvToast.toast('Need to Select Pic WD FW', {
                                title: 'FW Target',
                                variant: 'danger',
                                solid: true
                            });
                            continue;
                        }

                        curFwDev.fwPicTargetVerStr = curFwSel.verStr;
                        curFwDev.fwPicTargetVerNum = curFwSel.verNum;

                        this.$store.dispatch('setPicFwAction', curFwDev).then(() => {
                            this.$bvToast.toast('Pic WD FW Target Changed', {
                                title: 'WD Target',
                                variant: 'success',
                                solid: true
                            });
                            this.$store.dispatch('getDeviceFwList');
                        }).catch(() => {
                            this.$bvToast.toast('Error Changing Pic WD FW Target', {
                                title: 'WD Target',
                                variant: 'danger',
                                solid: true
                            });
                        });
                    }
                }
            }
            else if (this.fwDevOptions[5].name === item.option.name) {
                EventBus.$emit("ChangeWdPriority");
            }
            else if (this.fwDevOptions[6].name === item.option.name) {
                EventBus.$emit("ChangeWdMode");
            }
        },
        contextFwSelect(item) {
            //console.log(item.option.name);
            if (this.fwOptions[0].name === item.option.name) {
                EventBus.$emit("AddNewFw");
            }
            else if (this.fwOptions[2].name === item.option.name) {
                let fwVerInfo = {
                    fwVerNum: this.$store.getters.selectedFw.verNum,
                    devType: this.$store.getters.selectedFw.deviceType
                }
                this.$store.dispatch('deleteFwVersion', fwVerInfo).then(() => {
                    this.$bvToast.toast('FW Deleted', {
                        title: 'Delete FW',
                        variant: 'success',
                        solid: true
                    });
                    this.$store.dispatch('getFwList', 'InsectControl');
                    this.$store.dispatch('getFwList', 'CoolMist');
                    this.$store.dispatch('getFwList', 'PicWd');
                }).catch(() => {
                    this.$bvToast.toast('Error Deleting FW', {
                        title: 'Delete FW',
                        variant: 'danger',
                        solid: true
                    });
                })
            }
        }
    },
    computed: {
        hasSelection() {
            if (this.$store.getters.selectedDeviceFw !== null) {
                if (this.$store.getters.selectedDeviceFw.length > 0)
                    return true;
                else
                    return false;
            }
            else
                return false;
        }
    }
}
</script>

<style scoped>
.vue-simple-context-menu {
    background-color: #99caef;
}
.vue-simple-context-menu__divider {
    background-color: #2c3e50;
}
</style>
