<template>
    <div>
        <b-modal
            ref="fw-mode-modal"
            hide-footer
            centered
            title="Change FW Update Mode"
        >
            <div class="d-block text-center">
                <h5>FW Update Mode</h5>
            </div>
            <hr>
            <b-container>
                <b-row>
                    <b-col cols="4">
                        Mode:
                    </b-col>
                    <b-col>
                        <b-form-select v-model="modeSelected" :options="modeOptions"></b-form-select>
                    </b-col>
                </b-row>
            </b-container>
            <hr>
            <b-container class="text-center">
                <b-row cols="2">
                    <b-col>
                        <b-button variant="success" block @click="modeFwOK">OK</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="warning" block @click="modeFwCancel">Cancel</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus';

export default {
    name: "FwDevModeDialog",
    data() {
        return {
            modeSelected: 0,
            modeOptions: [
                {value: 0, text: "Do Nothing"},
                {value: 1, text: "Recently Updated"},
                {value: 2, text: "Update To Latest Ver."},
                {value: 3, text: "Update To Target Ver."},
            ]
        }
    },
    created() {

    },
    mounted() {
        EventBus.$on("ChangeFwMode", () => {
            this.modeSelected = this.$store.getters.selectedDeviceFw[0].fwUpdate;
            this.$refs['fw-mode-modal'].show();
        });
    },
    beforeDestroy() {
        EventBus.$off("ChangeFwMode");
    },
    methods: {
        modeFwOK() {
            this.$refs['fw-mode-modal'].hide();
            let selListFwDev = JSON.parse(JSON.stringify(this.$store.getters.selectedDeviceFw));
            if (selListFwDev.length > 0) {
                for (let i = 0; i < selListFwDev.length; i++) {
                    let curFwDev = selListFwDev[i];
                    curFwDev.fwUpdate = this.modeSelected;
                    this.$store.dispatch('setDeviceFwAction', curFwDev).then(() => {
                        this.$bvToast.toast('Device FW Update Mode Changed', {
                            title: 'FW Mode',
                            variant: 'success',
                            solid: true
                        });
                        this.$store.dispatch('getDeviceFwList');
                    }).catch(() => {
                        this.$bvToast.toast('Error Changing Device FW Update Mode', {
                            title: 'FW Mode',
                            variant: 'danger',
                            solid: true
                        });
                    });
                }
            }
        },
        modeFwCancel() {
            this.$refs['fw-mode-modal'].hide();
        }
    },
    computed: {

    }
}
</script>

<style scoped>

</style>
