<template>
    <div>
        <b-modal
            ref="fw-pri-modal"
            hide-footer
            centered
            title="Change FW Update Priority"
        >
            <div class="d-block text-center">
                <h5>FW Update Priority</h5>
            </div>
            <hr>
            <b-container>
                <b-row>
                    <b-col cols="4">
                        Priority:
                    </b-col>
                    <b-col>
                        <b-form-select v-model="priSelected" :options="priOptions"></b-form-select>
                    </b-col>
                </b-row>
            </b-container>
            <hr>
            <b-container class="text-center">
                <b-row cols="2">
                    <b-col>
                        <b-button variant="success" block @click="priFwOK">OK</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="warning" block @click="priFwCancel">Cancel</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus';

export default {
    name: "FwDevPriDialog",
    data() {
        return {
            priSelected: 0,
            priOptions: [
                {value: 0, text: "During Update Hours"},
                {value: 1, text: "When No Schedule Within 1 Hour"},
                {value: 2, text: "Immediately"},
            ]
        }
    },
    created() {

    },
    mounted() {
        EventBus.$on("ChangeFwPriority", () => {
            this.priSelected = this.$store.getters.selectedDeviceFw[0].fwUpdatePriority;
            this.$refs['fw-pri-modal'].show();
        });
    },
    beforeDestroy() {
        EventBus.$off("ChangeFwPriority");
    },
    methods: {
        priFwOK() {
            this.$refs['fw-pri-modal'].hide();
            let selListFwDev = JSON.parse(JSON.stringify(this.$store.getters.selectedDeviceFw));
            if (selListFwDev.length > 0) {
                for (let i = 0; i < selListFwDev.length; i++) {
                    let curFwDev = selListFwDev[i];
                    curFwDev.fwUpdatePriority = this.priSelected;
                    this.$store.dispatch('setDeviceFwAction', curFwDev).then(() => {
                        this.$bvToast.toast('Device FW Update Priority Changed', {
                            title: 'FW Priority',
                            variant: 'success',
                            solid: true
                        });
                        this.$store.dispatch('getDeviceFwList');
                    }).catch(() => {
                        this.$bvToast.toast('Error Changing Device FW Update Priority', {
                            title: 'FW Priority',
                            variant: 'danger',
                            solid: true
                        });
                    });
                }
            }
        },
        priFwCancel() {
            this.$refs['fw-pri-modal'].hide();
        }
    },
    computed: {

    }
}
</script>

<style scoped>

</style>
