<template>
    <div>
        <b-modal
            ref="add-fw-modal"
            hide-footer
            centered
            title="Add New FW"
        >
            <div class="d-block text-center">
                <h5>New FW Version</h5>
            </div>
            <hr>
            <b-container>
                <b-row>
                    <b-col cols="4">
                        Type:
                    </b-col>
                    <b-col>
                        <b-form-select v-model="selected" :options="options"></b-form-select>
                    </b-col>
                </b-row>
                <p></p>
                <b-row>
                    <b-col cols="4">
                        Major:
                    </b-col>
                    <b-col>
                        <b-form-input v-model="form.verMajor" type="number"></b-form-input>
                    </b-col>
                </b-row>
                <p></p>
                <b-row>
                    <b-col cols="4">
                        Minor:
                    </b-col>
                    <b-col>
                        <b-form-input v-model="form.verMinor" type="number"></b-form-input>
                    </b-col>
                </b-row>
                <p></p>
                <b-row>
                    <b-col cols="4">
                        Build:
                    </b-col>
                    <b-col>
                        <b-form-input v-model="form.verBuild" type="number"></b-form-input>
                    </b-col>
                </b-row>
            </b-container>
            <hr>
            <b-container class="text-center">
                <b-row cols="2">
                    <b-col>
                        <b-button variant="success" block @click="addFwOK">OK</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="warning" block @click="addFwCancel">Cancel</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus';

export default {
    name: "AddFwDialog",
    data() {
        return {
            form: {
                verMajor: 0,
                verMinor: 0,
                verBuild: 0,
                deviceType: ''
            },
            selected: 0,
            options: [
                { value: 0, text: 'Insect Control' },
                { value: 1, text: 'Cool Mist' },
                { value: 2, text: 'Pic WD' }
            ]
        }
    },
    created() {

    },
    mounted() {
        EventBus.$on("AddNewFw", () => {
            this.$refs['add-fw-modal'].show();
        });
    },
    beforeDestroy() {
        EventBus.$off("AddNewFw");
    },
    methods: {
        addFwOK() {
            this.$refs['add-fw-modal'].hide();
            let fwListRef = [];
            let fwBaseUrl = "/fw/sm_fw_";
            if (this.selected === 0) {
                fwListRef = this.$store.getters.fwListInsect;
                fwBaseUrl = "/fw/sm_fw_";
                this.form.deviceType = "InsectControl";
            }
            else if (this.selected === 1) {
                fwListRef = this.$store.getters.fwListCoolMist;
                fwBaseUrl = "/fw/cm_fw_";
                this.form.deviceType = "CoolMist";
            }
            else if (this.selected === 2) {
                fwListRef = this.$store.getters.fwListPicWd;
                fwBaseUrl = "/fw/pic_fw_";
                this.form.deviceType = "PicWd";
            }
            let nextIdx = 0;
            let newFwEntry = {};
            for (let i = 0; i < fwListRef.length; i++) {
                if (fwListRef[i].idx >= nextIdx)
                    nextIdx = fwListRef[i].idx + 1;
            }
            newFwEntry.idx = nextIdx;
            if (this.selected === 0 || this.selected === 1) {
                newFwEntry.url = fwBaseUrl + this.form.verMajor + "_" + this.form.verMinor + "_" + this.form.verBuild + ".bin";
                newFwEntry.verStr = this.form.verMajor + "." + this.form.verMinor + "." + this.form.verBuild;
                newFwEntry.verNum = (((parseInt(this.form.verMajor)) * 10000) + ((parseInt(this.form.verMinor)) * 100) + (parseInt(this.form.verBuild)));
            }
            else if (this.selected === 2) {
                newFwEntry.url = fwBaseUrl + this.form.verMajor + "_" + this.form.verMinor + ".hex";
                newFwEntry.verStr = this.form.verMajor + "." + this.form.verMinor;
                newFwEntry.verNum = (((parseInt(this.form.verMajor)) * 100) + (parseInt(this.form.verMinor)));
            }
            newFwEntry.deviceType = this.form.deviceType;
            //console.log(newFwEntry);
            this.$store.dispatch('addNewFwVersion', newFwEntry).then(() => {
                this.$bvToast.toast('New FW Version Added', {
                    title: 'Add FW',
                    variant: 'success',
                    solid: true
                });
                this.$store.dispatch('getFwList', 'InsectControl');
                this.$store.dispatch('getFwList', 'CoolMist');
                this.$store.dispatch('getFwList', 'PicWd');
                this.$store.commit('storeSelectedFw', null);
            }).catch(() => {
                this.$bvToast.toast('Error Adding New FW', {
                    title: 'Add FW',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        addFwCancel() {
            this.$refs['add-fw-modal'].hide();
        }
    },
    computed: {

    }
}
</script>

<style scoped>

</style>
