<template>
    <div>
        <b-modal
            ref="add-dev-fw-modal"
            hide-footer
            centered
            title="Add Device to FW List"
        >
            <div class="d-block text-center">
                <h5>Device List</h5>
            </div>
            <hr>
            <b-container>
                <simple-device-list id="OldDevice"></simple-device-list>
            </b-container>
            <hr>
            <b-container class="text-center">
                <b-row cols="2">
                    <b-col>
                        <b-button variant="success" block @click="devSelOK">OK</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="warning" block @click="devSelCancel">Cancel</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus';
import SimpleDeviceList from "@/components/AdminModules/Devices/SimpleDeviceList";
export default {
    name: "AddDevToFwListDialog",
    data() {
        return {

        }
    },
    components: {
        SimpleDeviceList
    },
    created() {

    },
    mounted() {
        EventBus.$on("AddDevFwList", () => {
            this.$refs['add-dev-fw-modal'].show();
        });
    },
    beforeDestroy() {
        EventBus.$off("AddDevFwList");
    },
    methods: {
        devSelOK() {
            this.$refs['add-dev-fw-modal'].hide();
            const devListRef = this.$store.getters.adminDevList;
            const devFwListRef = this.$store.getters.deviceFwList;
            for (let i = 0; i < devListRef.length; i++) {
                for (let j = 0; j < devFwListRef.length; j++) {
                    if (devListRef[i].deviceId === devFwListRef[j].deviceId) {
                        this.$bvToast.toast('That Device Is Already In The List', {
                            title: 'Add Device to FW List',
                            variant: 'success',
                            solid: true
                        });
                        return;
                    }
                }
            }
            let curFwDev = {};
            const devRef = this.$store.getters.swapDevOld;
            curFwDev.deviceId = devRef.deviceId;
            curFwDev.fwTargetVerStr = "";
            curFwDev.fwTargetVerNum = 0;
            curFwDev.fwUpdatePriority = 0;
            curFwDev.fwUpdate = 0;
            this.$store.dispatch('setDeviceFwAction', curFwDev).then(() => {
                this.$bvToast.toast('Device Added to FW List', {
                    title: 'Add Device to FW List',
                    variant: 'success',
                    solid: true
                });
                this.$store.dispatch('getDeviceFwList');
            }).catch(() => {
                this.$bvToast.toast('Error Adding Device to FW List', {
                    title: 'Add Device to FW List',
                    variant: 'danger',
                    solid: true
                });
            });

        },
        devSelCancel() {
            this.$refs['add-dev-fw-modal'].hide();
        }
    },
    computed: {

    }
}
</script>

<style scoped>

</style>
